import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import PostsList from "@components/postsList"
import GlowBackground from "@components/elements/glowBackground"

const News = ({ data }: any) => {
  const { meta_title, meta_description, meta_image, posts_list } =
    data.prismicNews.data

  const postsItems = posts_list.map(({ post }) => {
    let thumbnailImageData = null
    let hasVideo = false

    for (let el of post.document.data.body) {
      if (el.slice_type === "paragraph") {
        if (el.primary.paragraph_image) {
          thumbnailImageData = el.primary.paragraph_image
        }

        if (
          (el.primary.youtube_link && el.primary.youtube_link.html) ||
          (el.primary.video && el.primary.video.url)
        ) {
          hasVideo = true
        }
      }
    }

    return {
      id: post.document.id,
      slug: post.document.uid,
      title: post.document.data.title.text,
      published: post.document.data.published,
      type: post.document.data.type,
      postType: "news",
      imageData: thumbnailImageData,
      hasVideo,
    }
  })

  return (
    <Layout
      title={meta_title}
      description={meta_description}
      metaImage={meta_image?.url}
    >
      <GlowBackground>
        <PostsList title="News" items={postsItems} />
      </GlowBackground>
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query {
    prismicNews {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        posts_list {
          post {
            document {
              ... on PrismicNewsPost {
                id
                uid
                data {
                  title {
                    text
                  }
                  published(formatString: "DD.MM.YY")
                  type
                  body {
                    ... on PrismicNewsPostDataBodyParagraph {
                      id
                      primary {
                        paragraph_image {
                          gatsbyImageData(
                            placeholder: BLURRED
                            aspectRatio: 2.14
                          )
                        }
                        youtube_link {
                          html
                        }
                        video {
                          url
                        }
                      }
                      slice_type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
